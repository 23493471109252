<template>
  <div>
    <h1>Welcome</h1>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BLink,
  },
}
</script>

<style>

</style>
